import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import Layout from "../components/common/Layout/Layout";
import { SEO } from "../components/common";
import { Steps, Contact } from "../components/landing";
import IntroStatic from "../components/landing/StaticPages/Intro";

import { Title, Container } from "../styles";

const IndexPage = () => {
  const { t, i18n } = useTranslation();
  const { language } = useI18next();
  const isRTL = i18n.dir() === "rtl";
  return (
    <>
      <SEO
        lang={language}
        title={t("faq:title")}
        description={t("faq:description")}
        keywords={[
          t("faq:How to Download Video Online"),
          t("faq:How to save video online from any websites"),
          t("faq:title"),
        ]}
      />
      <IntroStatic
        title={t("common:FAQ")}
        titleContent={t("faq:How to save video from the internet?")}
      />
      <Steps />
      <Container direction={isRTL ? "rtl" : ""}>
        <Title as="h3" fontSize="29pt" fontMobile="24pt">
          {t("faq:How to save video online from any websites")}
        </Title>
        <p>{t("faq:HowToSave[text]5")}</p>
      </Container>
      <Contact />
    </>
  );
};

const customProps = {
  localeKey: "fag", // same as file name in src/i18n/translations/your-lang/index.js
};

export default Layout(customProps)(IndexPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "home", "faq"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
